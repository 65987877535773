import React from 'react';
import Glide from '@glidejs/glide';

import ImageItem from '../../components/ImageItem';
import TextItem from '../../components/TextItem';
import LinkItem from '../../components/LinkItem';

import { API } from '../../API';

export default class About extends React.Component {
	state = { loading: true };

	constructor() {
		super();
		this.glide = new Glide('.glide');
	}

	load = () => {
		return API.About.get()
		.then((json) => {
			this.setState(json);
		});
	}

	componentDidMount() {
		this.load()
		.then(() => this.setState({loading: false}, () => {
			this.glide.mount();
			// let mql = window.matchMedia('(max-width: 500px)');
			// if (!mql.matches) {
			// 	this.glide.mount();
			// }
		}));
	}

	next = () => {
		this.glide.go('>');
	}

	prev = () => {
		this.glide.go('<');
	}

	render() {
		if (this.state.loading)
			return <div />;

		return (
			<section className="about-detail">
				<div className="glide">
					<div className="glide__track" data-glide-el="track">
						<ul className="glide__slides">
							{
								this.state.content.map((item, i) => {
									console.log('item.type', item.type);
									switch(item.type) {
										case 'Image':
											return (
												<ImageItem
													key={`project-item-${i}}`}
													image={item.content}
													className='about-item glide__slide'
												/>
											);

										case 'Link':
											return (
												<LinkItem
													key={`project-item-${i}}`}
													href={item.source}
													title={item.title}
													className='about-item glide__slide'
												/>
											);

										case 'RichText':
										case 'Text':
											return (
												<TextItem
													key={`project-item-${i}}`}
													content={item.content}
													className='about-item glide__slide'
												/>
											);

										default:
											return null;
									}
								})
							}
						</ul>
					</div>
				</div>
				<div className="about-controls">
					<button className="about-controls-prev" onClick={this.prev} />
					<button className="about-controls-next" onClick={this.next} />
				</div>
			</section>
		);
	}
}


