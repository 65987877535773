import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Navigation from './components/Navigation';

import Post from './containers/Post';
import About from './containers/About';
import Contact from './containers/Contact';
import Shelf from './containers/OnOurShelf';
import FourOhFour from './containers/404';
import Plot from './containers/Plot';
import PlotList from './containers/PlotList'
import ReferenceList from './containers/ReferenceList'
import ConnectList from './containers/ConnectList'

export default function App() {
  return (
    <Router>
      <div id="wrapper">
        <Navigation />
        <Switch>
          <Route exact path="/plot/:slug" component={ Plot } />
          <Route exact path="/plots" component={ PlotList } />
          <Route exact path="/connect" component={ ConnectList } />
          <Route exact path="/reference" component={ ReferenceList } />
          <Route exact path="/about" component={ About } />
          <Route exact path="/contact" component={ Contact } />
          <Route exact path="/on-our-shelf" component={ Shelf } />
          <Route exact path="/" component={ Post } />
          <Route component={FourOhFour} />
        </Switch>
      </div>
    </Router>
  );
}

