import React, { useEffect } from 'react';

import Glide from '@glidejs/glide';
import ReactPlayer from 'react-player';

import ImageItem from '../ImageItem';

function Slideshow({images, id}) {
	const glide = new Glide(`.${id}`);

	useEffect(() => {
		glide.mount();
	}, [glide]);

	const next = () => {
		glide.go('>');
	}

	const prev = () => {
		glide.go('<');
	}
console.log(images);
	return (
		<div className="slideshow">
			<div className={`glide ${id}`}>
				<div className="glide__track" data-glide-el="track">
					<ul className="glide__slides">
						{
							images && images.map(({content, type}, i) => {
								if (type === 'Video' && content.video.url) {
									return (
										<div className="video-type" key={`slideshow-item-${i}}`}>
											<ReactPlayer url={content.video.url} controls width="100%" />
										</div>
									);
								}

								if (type === 'Image') {
									return (
										<ImageItem
											key={`slideshow-item-${i}}`}
											image={content.image}
											className='slideshow-item glide__slide'
											caption={content.caption}
										/>
									);
								}
								return null;
							})
						}
					</ul>
				</div>
			</div>
			<div className="slideshow-controls">
				<button className="slideshow-controls-prev" onClick={prev} />
				<button className="slideshow-controls-next" onClick={next} />
			</div>
		</div>
	);
};

export default Slideshow;