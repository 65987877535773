import React from 'react';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import { API } from '../../API';

export default class List extends React.Component {
	state = { items: [] };


	componentDidMount() {
		this.load();
	}

	load = () => {
		return API.Connect.all()
		.then((json) => {
      this.setState({
			  items: json,
		  })
    });
	}

	render() {
		const { items } = this.state;
		return (
			<section className="connect-section">
				<div className="site-list">
				{
					items.map(({image, caption, url}, i) => {
						return (
							<div className='site-thumbnail connect-list-item' key={url.href}>
								<Thumb image={image} />
								<div
									className='post-description'
									dangerouslySetInnerHTML={{__html: caption }}
								/>
							</div>
						)
					})
				}
				</div>
			</section>
		);
	}
}

const Thumb = ({image}) => {
	let src = false;
	if (image.url) {
		// check if it is a gif
		if (image.url.indexOf('gif') > 0) {
			src = image.url;
		} else {
			if (image.location === 'cloud') {
				src = image.pattern.replace('%s', 'w_2400').replace('http:', 'https:');
			} else {
				src = `${image.media_endpoint}/w_2400/${image.filename}`;
			}
		}
	}
	
	if (src) {
		return (
			<Zoom>
				<img src={src} alt="" />
			</Zoom>
		)
	}
	return null;
}
