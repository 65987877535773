import React from 'react';
import { NavLink } from 'react-router-dom';

// import { API } from '../../API';

const Nav = (props) => {
  // const location = useLocation();
	// const [data, setData] = useState(false);

	// useEffect(() => {
	// 	API.Plot.all()
	// 	.then((json) => {
	// 		setData(json);
	// 	});
	// }, []);

	return (
    <header className="header-fixed">
      <div className="header-wrapper">
        <h1 className="header__header">
          <NavLink to="/">
            <span className="sr-only">TEST PLOT</span>
          </NavLink>
        </h1>
        <nav>
          {
            // data && data.map(({slug, title}) => (<div key={slug}><NavLink to={`/Plot/${slug}`}>{title}</NavLink></div>))
          }
          <div>
            <NavLink to="/plots">Plots</NavLink>
          </div>
          <div>
            <NavLink to="/about">About</NavLink>
          </div>
          <div>
            <NavLink to="/connect">Connect</NavLink>
          </div>
          <div>
            <NavLink to="/reference">Media</NavLink>
          </div>
          <div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://testplotlog.cargo.site/"
            >
              LOG
            </a>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Nav;