import React from 'react';

const TextItem = ({content, className='project-item'}) => {
	return (
		<div className={`${className} text-type`}>
			<div className="item-inner" dangerouslySetInnerHTML={{__html: content}} />
		</div>
	);
};

export default TextItem;
