import React from 'react';

import { API } from '../../API';

export default class PlotList extends React.Component {
	state = { items: [] };


	componentDidMount() {
		this.load();
	}

	load = () => {
		return API.Plot.all()
		.then((json) => {
      this.setState({
			  items: json,
		  })
    });
	}

	render() {
		const { items } = this.state;
		return (
			<section>
				<div className="site-list">
				{
					items.map(({cover, title, slug}, i) => {
						const url = `/plot/${slug}`;

						if (cover.url) {
							let src = '';
							// check if it is a gif
							if (cover.url.indexOf('gif') > 0) {
								src = cover.url;
							} else {
								if (cover.location === 'cloud') {
									src = cover.pattern.replace('%s', 'w_2400').replace('http:', 'https:');
								} else {
									src = `${cover.media_endpoint}/w_2400/${cover.filename}`;
								}
							}
							return (
								<a className='site-thumbnail' key={slug} href={url}>
                  <img src={src} alt="" />
                  <div className='post-description'>{title}</div>
								</a>
							)
						} else {
							return (
								<a className='site-thumbnail' key={slug} href={url}>
									<div className='post-description'>{title}</div>
								</a>
							)
						}
					})
				}
				</div>
			</section>
		);
	}
}


// import React from 'react';
// import { arrayOf, shape, string } from 'prop-types';
// import { NavLink } from 'react-router-dom';

// import { API } from '../../API';

// import {
//   container,
//   item,
//   thumb,
//   name,
// } from './style.module.css';


// const Projects = ({ data, url }) => (
//   <main className={container}>
//     <SEO title={url === '/project/small-works' ? 'SMALL WORKS' : 'PROJECTS'} />
//     {
//       data.map(({ cover, slug, title }) => {
//         const style = {
//           backgroundImage: `url(${cover.media_endpoint}/w_700/${cover.filename})`,
//         };
//         return (
//           <div className={item} key={slug}>
//             <NavLink to={`/project/${slug}`}>
//               <div className={thumb} style={style} />
//               <h2 className={name}>{title}</h2>
//             </NavLink>
//           </div>
//         );
//       })
//     }
//   </main>
// );

// Projects.propTypes = {
//   data: arrayOf(shape({})).isRequired,
//   url: string.isRequired,
// };

// export const ProjectList = withAPI(Projects, '/project');
// export const SmallProjectList = withAPI(Projects, '/project/small-works');
