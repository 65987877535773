import React from 'react';
// import { render } from 'react-dom';
// import { connect } from 'react-redux';
// import { Link } from 'react-router';
// import _ from 'lodash';
import LazyLoad from 'react-lazyload';

import { API } from '../../API';
import VideoItem from '../../components/VideoItem'

export default class Post extends React.Component {
	state = { items: [] };


	componentDidMount() {
		this.load();
	}

	load = () => {
		return API.Home.all()
		.then(({ posts = [], intro = '', image = false }) => this.setState({
			items: posts,
			intro,
			image,
		}));
	}

	render() {
		var { items, intro, image: hero } = this.state;
		let heroSrc = '';
		if (hero && hero.url) {
			if (hero.url.indexOf('gif') > 0) {
				heroSrc = hero.url;
			} else {
				if (hero.location === 'cloud') {
					heroSrc = hero.pattern.replace('%s', 'w_2400').replace('http:', 'https:');
				} else {
					heroSrc = `${hero.media_endpoint}/w_2400/${hero.filename}`;
				}
			}
		}


		return (
			<section>
				<div className="home-intro">
					<div className="home-intro__text" dangerouslySetInnerHTML={{__html: intro}} />
					{
						hero && (
							<div className="home-intro__hero">
								<img src={heroSrc} alt="" />
							</div>
						)
					}
				</div>
				<ul className="post-list">
				{
					items.map(({image, description, video = false}, i) => {
						if (image && image.url) {
							let src = '';
							// check if it is a gif
							if (image.url.indexOf('gif') > 0) {
								src = image.url;
							} else {
								if (image.location === 'cloud') {
									src = image.pattern.replace('%s', 'w_2400').replace('http:', 'https:');
								} else {
									src = `${image.media_endpoint}/w_2400/${image.filename}`;
								}
							}
							return (
								<li className='image-post' key={image.url}>
									<LazyLoad offset={1000} height={200} once>
										<img src={src} alt="" />
										{
											description && <div className='post-description' dangerouslySetInnerHTML={{ __html: description }} />
										}
									</LazyLoad>
								</li>
							)
						} else if (description) {
							return (
								<li className='text-post' key={description}>
									<div className='post-description' dangerouslySetInnerHTML={{ __html: description }} />
								</li>
							)
						} else if (video && video.url) {
							return (
								<li className='video-post' key={video.url}>
									<VideoItem url={video.url} />
								</li>
							)
							// let src = '';
							// // check if it is a gif
							// if (image.url.indexOf('gif') > 0) {
							// 	src = image.url;
							// } else {
							// 	if (image.location === 'cloud') {
							// 		src = image.pattern.replace('%s', 'w_2400').replace('http:', 'https:');
							// 	} else {
							// 		src = `${image.media_endpoint}/w_2400/${image.filename}`;
							// 	}
							// }
							// return (
							// 	<li className='image-post' key={image.url}>
							// 		<LazyLoad offset={1000} height={200} once>
							// 			<img src={src} alt="" />
							// 			{
							// 				description && <div className='post-description' dangerouslySetInnerHTML={{ __html: description }} />
							// 			}
							// 		</LazyLoad>
							// 	</li>
							// )
						}
						return null;
					})
				}
				</ul>
			</section>
		);
	}
}
