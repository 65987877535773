/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import ReactPlayer from 'react-player';

import {
  string,
} from 'prop-types';

import playIcon from './playIcon.svg';
import pauseIcon from './pauseIcon.svg';

const VideoItem = ({ url, className }) => {
  const [playing, setPlaying] = useState(false);

  if (!url) {
    return <span />;
  }

  return (
    <div className={`${className} video-container`}>
      <ReactPlayer
        url={url.replace('http:', 'https:')}
        height="100%"
        width="100%"
        playing={playing}
      />
      <button className={`video-playButton ${playing ? 'video-is-playing' : 'video-isPaused'}`} type="button" onClick={() => setPlaying(!playing)}>
        <img alt="" src={playing ? pauseIcon : playIcon} className="video-controlIcon" />
      </button>
    </div>
  );
};

VideoItem.propTypes = {
  url: string,
  className: string,
};

VideoItem.defaultProps = {
  url: '',
  className: '',
};

export default VideoItem;
