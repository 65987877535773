import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import ReactPlayer from 'react-player';

import ImageItem from '../../components/ImageItem';
import TextItem from '../../components/TextItem';
import LinkItem from '../../components/LinkItem';
import Slideshow from '../../components/Slideshow';

import { API } from '../../API';

function Plot(props) {
	const [ data, setData ] = useState(false);
	useEffect(() => {
		API.Plot.get(props.match.params.slug)
		.then((json) => {
			setData(json);
		});
	}, [props.match.params.slug])

	if (!data) {
		return <div />;
	}

	const {
		content = [],
		cover,
		description,
		meta,
		title,
	}  = data;

	let coverSrc = '';
	if (cover.location === 'cloud') {
		coverSrc = cover.pattern.replace('%s', 'w_2400').replace('http:', 'https:');
	} else {
		coverSrc = `${cover.media_endpoint}/w_2400/${cover.filename}`;
	}


	return (
		<section className="site-detail">
			<div className="coverContainer">
				<img src={coverSrc} alt={title} />
			</div>

			{
				description && (
					<div className="desc">
						<h2 className="descTitle">{title}</h2>
						<div dangerouslySetInnerHTML={{ __html: description }} />
					</div>
				)
			}
			{
				meta && (
					<aside
						className="aside"
						dangerouslySetInnerHTML={{ __html: meta }}
					/>
				)
			}
			<div className="contentContainer">
				{
					content.map(({
						content: itemContent,
						title: itemTitle,
						type,
						uuid,
						href,
					}, i) => {

						switch (type) {
							case 'Image':
								return <ImageItem key={`${uuid}-${i}`} image={itemContent} className="site-detail-item" />;

							case 'Slideshow':
								return <Slideshow key={`${uuid}-${i}`} id={`${uuid}-${i}`} images={itemContent} />;

							case 'Link':
								return <LinkItem key={`${uuid}-${i}}`} href={itemContent.href} title={itemContent.name} className="site-detail-item" />;

							case 'Heading':
								return <h2 key={`${uuid}-${i}}`} className="descTitle">{itemContent.title}</h2>;

							case 'Video':
								if (itemContent.video.url === false) {
									return null;
								}
								return (
									<div className="video-type" key={`${uuid}-${i}}`}>
										<ReactPlayer url={itemContent.video.url} controls width="100%" />
									</div>
								);

							case 'RichText':
							case 'Text':
								return <TextItem key={`${uuid}-${i}`} content={itemContent} className="site-detail-item" />;

							default:
								return null;
						}
					})
				}
			</div>
		</section>
	);
}

export default withRouter(Plot);