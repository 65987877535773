import React from 'react';

import { API } from '../../API';

export default class List extends React.Component {
	state = { reference: [], about: [] };


	componentDidMount() {
		this.load();
	}

	load = () => {
		return API.Reference.all()
		.then((json) => {
      this.setState(json)
    });
	}

	render() {
		console.log(this.state);
		const { reference, about } = this.state;
		return (
			<section className="reference-section">
				<h1>About Test Plot</h1>
				<ItemList items={about} />

				<h1>Inspiration</h1>
				<ItemList items={reference} />
			</section>
		);
	}
}

const ItemList = ({items}) => (
	<div className="site-list">
	{
		items.map(({image, copy, url}, i) => {
			return (
				<div className='site-thumbnail connect-list-item'>
					<Thumb image={image} href={url.href} />
					<div
						className='post-description'
						dangerouslySetInnerHTML={{__html: copy }}
					/>
				</div>
			)
		})
	}
	</div>
)


const Thumb = ({image, href = false}) => {
	let src = false;
	if (image.url) {
		// check if it is a gif
		if (image.url.indexOf('gif') > 0) {
			src = image.url;
		} else {
			if (image.location === 'cloud') {
				src = image.pattern.replace('%s', 'w_2400').replace('http:', 'https:');
			} else {
				src = `${image.media_endpoint}/w_2400/${image.filename}`;
			}
		}
	}
	
	if (src) {
		return (
			<a href={href} target="_blank" rel="noreferrer">
				<img src={src} alt="" />
			</a>
		)
	}
	return null;
}
