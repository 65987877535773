// const API_BASE = process.env.NODE_ENV === 'production' ? 'https://api.testplot.info' : 'http://api.testplot.test';
const API_BASE = 'https://api.testplot.info';
// const API_BASE = 'http://api.stage.testplot.info'
// const API_BASE = 'https://api.testplot.test';

function requestJSON(method, url, data={}) {
	switch(method) {
		case 'GET':
			return fetch(url, {
				'Accept-Encoding': 'gzip, deflate',
				'Content-Type': 'application/json'
			})
			.catch((ex) => { console.warn(`FAILED TO GET ${url}`, ex); })
			.then((response) => response.json())
			.catch((ex) => { console.warn('FAILED TO PARSE JSON', ex); });

		default:
			throw new Error('Invalid Method');
	}
}

const API = {};

API.Post = {};
API.Post.all = () => requestJSON('GET', `${API_BASE}/post`);

API.Home = {};
API.Home.all = () => requestJSON('GET', `${API_BASE}/home`);

API.Contact = {};
API.Contact.get = () => requestJSON('GET', `${API_BASE}/support`);

API.Plot = {};
API.Plot.all = () => requestJSON('GET', `${API_BASE}/site`);
API.Plot.get = (id) => requestJSON('GET', `${API_BASE}/site/${id}`);

API.Connect = {
	all: () => requestJSON('GET', `${API_BASE}/connect`),
};

API.Reference = {
	all: () => requestJSON('GET', `${API_BASE}/reference`),
};


API.Shelf = {};
API.Shelf.get = () => requestJSON('GET', `${API_BASE}/shelf`);

API.About = {};
API.About.get = () => requestJSON('GET', `${API_BASE}/about`);

export { API, API_BASE };