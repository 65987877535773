import React from 'react';

const PDFItem = ({href, title, className='project-item'}) => {
	return (
		<div className={`${className} file-type`}>
			<a className="item-inner" href={href} target="_blank" rel="noopener noreferrer"><div>{title}</div></a>
		</div>
	);
};

export default PDFItem;