import React from 'react';

const LinkItem = ({title, href, className='shelf-link link-item'}) => {
	return (
		<div className={`${className} link-type`}>
			<a className="item-inner" href={href} target="_blank" rel="noopener noreferrer"><div>{title}</div></a>
		</div>
	);
};

export default LinkItem;