import React from 'react';

const ImageItem = ({image, caption=false, className='project-item'}) => {
	console.log(image);
	if (!image.id && !caption)
		return <span></span>;

	let src = '';
	if (image.location === 'cloud') {
		src = image.pattern.replace('%s', 'w_2400').replace('http:', 'https:');
	} else {
		src = `${image.media_endpoint}/w_2400/${image.filename}`;
	}
	return (
		<div className={`${className} image-type`}>
			{
				image.filename && <img className="item-inner" src={src} alt="" />
			}
			{
				caption && <div className="image-type__caption" dangerouslySetInnerHTML={{__html: caption}} />
			}
		</div>
	);
};


export default ImageItem;