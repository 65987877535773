import React from 'react';

import ImageItem from '../../components/ImageItem';
import TextItem from '../../components/TextItem';
import LinkItem from '../../components/LinkItem';
import PDFItem from '../../components/PDFItem';

import { API } from '../../API';

export default class OnOurShelf extends React.Component {
	state = { loading: true };

	load = () => {
		return API.Shelf.get()
		.then((json) => {
			this.setState(json);
		});
	}

	componentDidMount() {
		this.load()
			.then(() => this.setState({loading: false}));
	}


	render() {
		console.log(this.state);
		if (this.state.loading)
			return <div />;

		const {
			content = [],
		}  = this.state;

		return (
			<section className="support-detail">
				{
					content.map((item, i) => {
						switch(item.type) {
							case 'Image':
								return <ImageItem key={`project-item-${i}}`} image={item.content} />;

							case 'Link':
								return <LinkItem key={`project-item-${i}}`} href={item.content.href} title={item.content.name} className="shelf-link" />;

							case 'PDF':
								return <PDFItem key={`project-item-${i}}`} href={item.content.file.url} title={item.content.title} className="shelf-file" />;

							case 'RichText':
							case 'Text':
								return <TextItem key={`project-item-${i}}`} content={item.content} />;

							default:
								return null;
						}
					})
				}
			</section>
		);
	}
}


